#gen2 {
    box-sizing: border-box;
    padding: 0 0;
    margin: 0 0;
    background-image: url(../assets/img/bg2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    overflow-x: hidden;
    font-size: 25px;
    height: 5000px;
    padding-top: 100px;
    position: relative;
}

h1 {
    font-size: xx-large;
    width: 100%;
    display: flex;
    justify-content: center;
}

.earth {
    position: absolute;
    bottom: 0px;
    z-index: -1;
}

.lady {
    position: absolute;
    bottom: 500px;
    left: 700px;
}

.rocketgen2 {
    position: relative;
    left: 1000px;
    top: -200px;
}

.text1 {
    margin-left: 40px;
}

.text2 {
    margin-left: 140px;
}

.text3 {
    margin-left: 90px;
}

.text4 {
    margin-left: 440px;
}

.text5 {
    position: absolute;
    right: 0px;
    margin-right: 100px;
    bottom: 300px;
    font-size: 50px;
}

.text5 h1 {
    font-size: 50px;
}

.text5 h6 {
    text-align: center;
}

@media only screen and (max-width: 460px) {
    .rocketgen2 {
        left: 250px;
        top: 0px;
        height: 200px;
        position: absolute;
    }
    .text1 {
        font-size: small;
        margin-left: 20px;
    }
    .text2 {
        font-size: small;
        margin-left: 60px;
    }
    .text3 {
        font-size: small;
        margin-left: 40px;
    }
    .text4 {
        font-size: small;
        margin-left: 80px;
    }

    .text5 {
        font-size: 12px;
        bottom: 60px;
        right: -80px;
    }
    .text5 h6 {
        font-size: x-small;
    }
    .earth {
        position: absolute;
        left: -150px;
        z-index: -1;
        height: 400px;
    }

    .lady {
        position: absolute;
        height: 1000px;
        bottom: 170px;
        left: 150px !important;
    }
}
