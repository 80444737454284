html {
  box-sizing: border-box;
  padding: 0 0;
  margin: 0 0;
  /* height: 2000px; */
  background-image: url(assets/img/bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow-x: hidden;
  font-size: 25px;
}