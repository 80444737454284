#about {
    height: 100vh;
  }
  
  .about__h1 {
    text-transform: uppercase;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 70px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-size: 50px;
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 40px;
  }
  
  .about__class {
    height: 500px;
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: space-between;
    margin: auto auto;
  }
  
  .text__section h1 {
    font-size: 2rem;
    letter-spacing: 7px;
    margin: 20px 0;
  }
  
  .img__section {
    width: 50%;
  }
  .about__img {
    width: 400px;
    height: 400px;
    top: 20px;
  }
  
  .text__section {
    width: 80%;
    font-size: 0.8rem;
    line-height: 1.5;
  }
  
  .pos1 {
    left: 50%;
  }
  
  .pos10 {
    position: absolute;
    width: 100px;
    left: 450px;
    top: -400px;
  }

  @media only screen and (max-width: 460px) {
    #about {
      height: 100%;
    }

    .pos1, .pos10, .img__section {
      display: none;
    }
    .about__h1 { 
     font-size: 1.5rem;
    }

    .text__section { 
      width: 100%;
      font-size: 0.4rem;
    }
    .text__section h1 {
   display: none;
    }
  }
  