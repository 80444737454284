@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
* {
  color: white !important;
  background-color: transparent !important;

}
body {
  font-family: "Righteous", cursive !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
