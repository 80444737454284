#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 2115px 1740px #fff, 2567px 193px #fff, 3000px 2063px #fff,
      1163px 2284px #fff, 2538px 2259px #fff, 1185px 1592px #fff,
      2482px 1218px #fff, 1906px 1965px #fff, 1233px 2317px #fff, 551px 647px #fff,
      1359px 225px #fff, 1410px 882px #fff, 2790px 1158px #fff, 2289px 2820px #fff,
      925px 2071px #fff, 363px 2774px #fff, 426px 102px #fff, 1045px 1304px #fff,
      1737px 1070px #fff, 2553px 150px #fff, 2040px 2413px #fff,
      1512px 2901px #fff, 1668px 1084px #fff, 1521px 1947px #fff, 462px 665px #fff,
      1020px 1254px #fff, 810px 2727px #fff, 1755px 1751px #fff,
      1002px 2043px #fff, 731px 1773px #fff, 2911px 1713px #fff,
      1989px 2054px #fff, 1459px 2043px #fff, 1911px 1723px #fff,
      1294px 427px #fff, 2239px 1683px #fff, 1202px 2089px #fff, 436px 338px #fff,
      234px 2282px #fff, 236px 1309px #fff, 562px 1897px #fff, 2280px 2143px #fff,
      1419px 317px #fff, 431px 110px #fff, 314px 2820px #fff, 2133px 625px #fff,
      106px 1653px #fff, 2784px 443px #fff, 521px 1698px #fff, 138px 1499px #fff,
      2587px 270px #fff, 1307px 2750px #fff, 1610px 1657px #fff, 362px 2877px #fff,
      2580px 2294px #fff, 1533px 1455px #fff, 167px 2436px #fff, 1000px 729px #fff,
      2033px 1901px #fff, 953px 559px #fff, 2789px 2764px #fff, 1130px 1087px #fff,
      254px 1778px #fff, 2684px 1158px #fff, 1256px 2160px #fff, 301px 2873px #fff,
      1823px 2889px #fff, 303px 2060px #fff, 1866px 2313px #fff,
      2489px 2254px #fff, 1953px 1819px #fff, 395px 1324px #fff,
      2453px 1756px #fff, 872px 809px #fff, 1434px 2757px #fff, 2617px 2807px #fff,
      53px 2397px #fff, 855px 949px #fff, 2698px 274px #fff, 2710px 2276px #fff,
      90px 1721px #fff, 2805px 739px #fff, 999px 2218px #fff, 1063px 2925px #fff,
      168px 556px #fff, 146px 76px #fff, 231px 1230px #fff, 2308px 957px #fff,
      1750px 2274px #fff, 897px 525px #fff, 206px 1362px #fff, 138px 2616px #fff,
      1196px 2136px #fff, 897px 2338px #fff, 1081px 1525px #fff, 2822px 110px #fff,
      799px 2176px #fff, 2589px 2031px #fff, 2627px 226px #fff, 1414px 2348px #fff,
      679px 874px #fff, 1060px 1142px #fff, 2577px 2415px #fff, 2413px 255px #fff,
      2708px 219px #fff, 2398px 1253px #fff, 2559px 936px #fff, 624px 818px #fff,
      1519px 741px #fff, 1346px 2826px #fff, 696px 835px #fff, 72px 1873px #fff,
      2021px 2566px #fff, 2079px 1872px #fff, 2226px 2935px #fff, 158px 901px #fff,
      1056px 2667px #fff, 1983px 2181px #fff, 2865px 2467px #fff,
      2300px 1393px #fff, 1761px 2427px #fff, 2544px 1279px #fff,
      1124px 184px #fff, 1992px 2731px #fff, 259px 1676px #fff, 196px 557px #fff,
      2671px 678px #fff, 1136px 97px #fff, 322px 1073px #fff, 258px 1749px #fff,
      664px 2811px #fff, 2170px 2572px #fff, 1248px 406px #fff, 2170px 937px #fff,
      2151px 982px #fff, 1108px 644px #fff, 2400px 311px #fff, 2357px 2360px #fff,
      2252px 2232px #fff, 1333px 299px #fff, 2121px 1139px #fff,
      2728px 2533px #fff, 1919px 491px #fff, 2559px 2962px #fff, 404px 1074px #fff,
      2628px 1184px #fff, 2596px 2220px #fff, 2081px 1863px #fff,
      2605px 1097px #fff, 521px 1352px #fff, 1048px 2875px #fff,
      2447px 1816px #fff, 1078px 794px #fff, 199px 585px #fff, 1445px 713px #fff,
      2515px 1656px #fff, 638px 1970px #fff, 1488px 944px #fff, 1681px 1737px #fff,
      1091px 2636px #fff, 2408px 2708px #fff, 1978px 1935px #fff,
      2419px 257px #fff, 1268px 2276px #fff, 1676px 2569px #fff,
      2873px 2065px #fff, 2231px 947px #fff, 1174px 48px #fff, 2812px 1288px #fff,
      128px 2259px #fff, 2520px 1445px #fff, 776px 1235px #fff, 21px 1492px #fff,
      2102px 439px #fff, 165px 554px #fff, 1346px 1994px #fff, 1218px 1457px #fff,
      565px 357px #fff, 1270px 868px #fff, 1881px 2062px #fff, 2690px 2664px #fff,
      2464px 1772px #fff, 1962px 2898px #fff, 254px 2721px #fff, 2179px 963px #fff,
      1003px 529px #fff, 71px 1322px #fff, 2978px 1024px #fff, 773px 147px #fff,
      184px 162px #fff, 2482px 1774px #fff, 2845px 137px #fff, 917px 902px #fff,
      1888px 503px #fff, 2731px 2053px #fff, 2451px 2839px #fff,
      2473px 1401px #fff, 272px 143px #fff, 1598px 727px #fff, 270px 54px #fff,
      1236px 290px #fff, 448px 2024px #fff, 1994px 1250px #fff, 1739px 1693px #fff,
      23px 2498px #fff, 1269px 504px #fff, 1257px 1421px #fff, 222px 917px #fff,
      2326px 2993px #fff, 1294px 2052px #fff, 1120px 2395px #fff,
      2144px 749px #fff, 1396px 113px #fff, 2881px 1639px #fff, 1352px 1906px #fff,
      2269px 1160px #fff, 1108px 2724px #fff, 1785px 1346px #fff,
      1334px 1995px #fff, 1191px 1960px #fff, 1409px 737px #fff,
      1299px 1800px #fff, 1382px 2126px #fff, 964px 576px #fff, 423px 887px #fff,
      2508px 336px #fff, 2586px 2591px #fff, 718px 1898px #fff, 106px 932px #fff,
      1525px 921px #fff, 2324px 725px #fff, 507px 2724px #fff, 1952px 1753px #fff,
      1789px 355px #fff, 1690px 279px #fff, 619px 1038px #fff, 984px 690px #fff,
      1275px 397px #fff, 2202px 2261px #fff, 2545px 1896px #fff, 1757px 995px #fff,
      294px 1775px #fff, 2954px 966px #fff, 953px 2584px #fff, 983px 2778px #fff,
      1691px 2295px #fff, 773px 349px #fff, 908px 1351px #fff, 613px 940px #fff,
      1609px 2386px #fff, 2029px 1798px #fff, 1410px 962px #fff, 310px 2953px #fff,
      1677px 654px #fff, 2046px 2087px #fff, 1251px 1877px #fff,
      1195px 2658px #fff, 2205px 23px #fff, 2607px 495px #fff, 1429px 1335px #fff,
      2181px 2247px #fff, 2828px 1835px #fff, 2484px 127px #fff,
      2096px 1121px #fff, 224px 1234px #fff, 267px 80px #fff, 2480px 1805px #fff,
      1443px 1876px #fff, 2197px 2434px #fff, 68px 2118px #fff, 1828px 1034px #fff,
      743px 91px #fff, 1807px 1829px #fff, 1778px 3000px #fff, 1905px 1267px #fff,
      2746px 497px #fff, 1993px 1417px #fff, 1750px 2843px #fff,
      2980px 2379px #fff, 957px 2661px #fff, 347px 1884px #fff, 2375px 274px #fff,
      1188px 545px #fff, 2328px 903px #fff, 2737px 817px #fff, 442px 112px #fff,
      344px 2944px #fff, 2445px 2003px #fff, 184px 2434px #fff, 2796px 1509px #fff,
      2986px 1493px #fff, 399px 1370px #fff, 845px 135px #fff, 2176px 811px #fff,
      134px 2916px #fff, 1177px 613px #fff, 244px 1944px #fff, 2173px 199px #fff,
      1429px 162px #fff, 1193px 1824px #fff, 1996px 1167px #fff, 822px 1104px #fff,
      164px 1447px #fff, 2735px 784px #fff, 2690px 1974px #fff, 2577px 904px #fff,
      866px 1229px #fff, 658px 510px #fff, 817px 923px #fff, 1980px 1545px #fff,
      1733px 2556px #fff, 524px 1804px #fff, 2762px 2231px #fff, 1594px 380px #fff,
      2227px 2978px #fff, 1472px 1988px #fff, 1193px 2774px #fff,
      467px 2354px #fff, 2694px 814px #fff, 1824px 239px #fff, 753px 1015px #fff,
      69px 973px #fff, 1277px 1883px #fff, 1453px 2042px #fff, 401px 478px #fff,
      2152px 588px #fff, 1148px 1101px #fff, 1932px 624px #fff, 1044px 2237px #fff,
      639px 185px #fff, 209px 2449px #fff, 1409px 2319px #fff, 2265px 1921px #fff,
      2094px 2850px #fff, 2315px 991px #fff, 2878px 2181px #fff, 2913px 965px #fff,
      1307px 2889px #fff, 1742px 1074px #fff, 2954px 1480px #fff,
      2012px 2977px #fff, 2479px 2495px #fff, 2933px 1174px #fff, 80px 1489px #fff,
      307px 987px #fff, 1159px 900px #fff, 863px 2644px #fff, 1665px 141px #fff,
      2169px 2760px #fff, 903px 2106px #fff, 1686px 2367px #fff, 662px 2478px #fff,
      563px 15px #fff, 793px 1243px #fff, 2190px 1840px #fff, 2023px 1174px #fff,
      2990px 752px #fff, 381px 2777px #fff, 209px 1590px #fff, 2541px 703px #fff,
      2288px 2386px #fff, 634px 1399px #fff, 1667px 1647px #fff,
      1805px 1269px #fff, 1580px 1260px #fff, 2883px 1981px #fff,
      2254px 293px #fff, 1857px 866px #fff, 1056px 343px #fff, 990px 173px #fff,
      794px 2976px #fff, 239px 1303px #fff, 1003px 1574px #fff, 2584px 1548px #fff,
      978px 2211px #fff, 335px 1050px #fff, 1397px 13px #fff, 897px 1972px #fff,
      2433px 69px #fff, 2273px 681px #fff, 1504px 899px #fff, 1452px 2842px #fff,
      2473px 996px #fff, 668px 1983px #fff, 2541px 1265px #fff, 2226px 2825px #fff,
      218px 734px #fff, 447px 2668px #fff, 1415px 1075px #fff, 1571px 2468px #fff,
      798px 638px #fff, 1808px 2526px #fff, 405px 769px #fff, 914px 1939px #fff,
      1671px 1380px #fff, 1714px 1458px #fff, 1916px 29px #fff, 2333px 1010px #fff,
      2884px 994px #fff, 1403px 1897px #fff, 2021px 2566px #fff, 246px 2023px #fff,
      2241px 2904px #fff, 145px 2666px #fff, 902px 2091px #fff, 1214px 935px #fff,
      1144px 526px #fff, 2098px 828px #fff, 2885px 2610px #fff, 2830px 1718px #fff,
      2417px 1730px #fff, 2011px 2354px #fff, 2654px 1979px #fff, 526px 776px #fff,
      2269px 1181px #fff, 990px 1505px #fff, 1074px 1179px #fff, 2420px 965px #fff,
      2645px 1003px #fff, 48px 1400px #fff, 967px 1481px #fff, 2860px 1637px #fff,
      595px 143px #fff, 2989px 1016px #fff, 2081px 833px #fff, 184px 2031px #fff,
      882px 565px #fff, 1716px 914px #fff, 1579px 2851px #fff, 1308px 1293px #fff,
      1482px 1191px #fff, 357px 1357px #fff, 2334px 2945px #fff, 47px 1566px #fff,
      1015px 659px #fff, 1686px 1073px #fff, 555px 353px #fff, 889px 1673px #fff,
      974px 351px #fff, 1263px 196px #fff, 2673px 827px #fff, 959px 273px #fff,
      109px 36px #fff, 1258px 441px #fff, 1420px 2361px #fff, 2291px 2071px #fff,
      1875px 1283px #fff, 131px 194px #fff, 1276px 1371px #fff, 59px 2299px #fff,
      629px 2342px #fff, 2207px 1641px #fff, 873px 2819px #fff, 578px 1516px #fff,
      306px 956px #fff, 726px 2053px #fff, 2636px 737px #fff, 1833px 1623px #fff,
      2359px 1251px #fff, 2550px 1657px #fff, 2932px 972px #fff,
      2183px 2542px #fff, 1943px 2229px #fff, 883px 1861px #fff, 689px 2171px #fff,
      1430px 473px #fff, 2350px 1520px #fff, 473px 516px #fff, 695px 826px #fff,
      84px 92px #fff, 2635px 483px #fff, 2750px 2360px #fff, 1438px 1211px #fff,
      892px 857px #fff, 1036px 45px #fff, 1770px 2955px #fff, 432px 164px #fff,
      775px 2580px #fff, 423px 2516px #fff, 2058px 585px #fff, 953px 349px #fff,
      2623px 18px #fff, 2369px 53px #fff, 2671px 2553px #fff, 649px 1534px #fff,
      975px 2096px #fff, 323px 2309px #fff, 1582px 312px #fff, 2076px 634px #fff,
      2700px 1485px #fff, 2411px 2916px #fff, 1361px 2834px #fff,
      1555px 2905px #fff, 2181px 1412px #fff, 221px 1482px #fff,
      2492px 2900px #fff, 1165px 291px #fff, 1398px 2859px #fff, 574px 288px #fff,
      1242px 1460px #fff, 2208px 2063px #fff, 2504px 2756px #fff,
      1456px 2452px #fff, 1484px 707px #fff, 2163px 2469px #fff,
      1316px 1724px #fff, 1201px 491px #fff, 1000px 1069px #fff,
      1901px 2471px #fff, 1794px 2998px #fff, 988px 943px #fff, 1542px 2705px #fff,
      2730px 715px #fff, 2256px 1160px #fff, 2160px 2539px #fff, 1540px 188px #fff,
      1241px 20px #fff, 2140px 1618px #fff, 209px 1252px #fff, 1586px 993px #fff,
      2197px 1601px #fff, 1919px 2867px #fff, 49px 2997px #fff, 2699px 2778px #fff,
      855px 2908px #fff, 1563px 1624px #fff, 1863px 1800px #fff,
      1443px 1540px #fff, 340px 2358px #fff, 576px 126px #fff, 1913px 2854px #fff,
      2190px 2636px #fff, 2978px 483px #fff, 1774px 2606px #fff,
      2946px 2094px #fff, 563px 152px #fff, 2168px 1233px #fff, 818px 2245px #fff,
      1142px 727px #fff, 207px 527px #fff, 397px 1232px #fff, 2787px 113px #fff,
      722px 2564px #fff, 2087px 1057px #fff, 577px 2271px #fff, 1630px 177px #fff,
      2254px 2716px #fff, 2278px 1991px #fff, 2457px 2933px #fff,
      1640px 784px #fff, 1929px 853px #fff, 2007px 2838px #fff, 2137px 287px #fff,
      2439px 18px #fff, 1570px 692px #fff, 2897px 264px #fff, 274px 360px #fff,
      77px 805px #fff, 232px 1719px #fff, 746px 687px #fff, 2535px 1679px #fff,
      616px 905px #fff, 2633px 2688px #fff, 2220px 1177px #fff, 1185px 1587px #fff,
      2274px 2219px #fff, 1416px 1073px #fff, 31px 1968px #fff, 1948px 2702px #fff,
      1890px 993px #fff, 2901px 764px #fff, 2386px 2633px #fff, 349px 1402px #fff,
      1264px 2744px #fff, 720px 969px #fff, 2210px 1437px #fff, 1039px 1915px #fff,
      846px 2779px #fff, 984px 422px #fff, 1823px 586px #fff, 2329px 2725px #fff,
      1783px 1326px #fff, 1768px 1557px #fff, 1843px 210px #fff,
      1717px 2643px #fff, 1526px 1137px #fff, 2334px 399px #fff, 528px 1081px #fff,
      699px 2666px #fff, 1654px 329px #fff, 2860px 2815px #fff, 2267px 485px #fff,
      1078px 1126px #fff, 864px 331px #fff, 2226px 361px #fff, 2568px 2091px #fff,
      2040px 2305px #fff, 1189px 1867px #fff, 442px 1827px #fff,
      1663px 2195px #fff, 2340px 2387px #fff, 329px 626px #fff, 2773px 1723px #fff,
      618px 1601px #fff, 895px 1788px #fff, 2776px 1519px #fff, 2660px 1111px #fff,
      789px 1717px #fff, 2377px 2003px #fff, 1042px 2980px #fff, 878px 2630px #fff,
      1248px 2002px #fff, 2519px 262px #fff, 886px 2354px #fff, 723px 126px #fff,
      1386px 1775px #fff, 338px 823px #fff, 155px 585px #fff, 319px 1667px #fff,
      54px 1625px #fff, 1332px 2016px #fff, 2628px 1562px #fff, 2126px 1353px #fff,
      2314px 2650px #fff, 2082px 2334px #fff, 2576px 148px #fff, 771px 1776px #fff,
      280px 2603px #fff, 2067px 1118px #fff, 621px 2155px #fff, 1556px 2427px #fff,
      912px 127px #fff, 650px 755px #fff, 1250px 403px #fff, 421px 1305px #fff,
      2339px 2246px #fff, 1167px 1234px #fff, 2586px 882px #fff, 2031px 784px #fff,
      2031px 164px #fff, 1851px 2645px #fff, 1207px 1257px #fff,
      2898px 2268px #fff, 2937px 709px #fff, 144px 249px #fff, 1899px 2716px #fff,
      2155px 1849px #fff, 202px 1296px #fff, 621px 2511px #fff, 903px 1045px #fff,
      1983px 2474px #fff, 1048px 2728px #fff, 2948px 2222px #fff,
      1375px 1863px #fff, 2670px 2568px #fff, 2449px 2633px #fff,
      2311px 2008px #fff, 7px 1338px #fff, 1452px 2131px #fff, 2474px 1283px #fff,
      1844px 877px #fff, 213px 1797px #fff, 64px 2891px #fff, 1510px 1071px #fff,
      1460px 1022px #fff, 1419px 1244px #fff, 1385px 330px #fff,
      2265px 1419px #fff, 1243px 2022px #fff, 2220px 959px #fff,
      1264px 1537px #fff, 436px 2500px #fff, 1798px 910px #fff, 59px 2421px #fff,
      2604px 1623px #fff, 101px 2928px #fff, 2283px 2338px #fff, 329px 1150px #fff,
      2343px 2421px #fff, 2750px 1120px #fff, 1255px 1748px #fff,
      1143px 2371px #fff, 212px 777px #fff, 871px 366px #fff, 356px 1905px #fff,
      289px 98px #fff, 1134px 2849px #fff, 1109px 1412px #fff, 1959px 991px #fff,
      2467px 817px #fff, 1501px 1263px #fff, 744px 2619px #fff, 749px 395px #fff,
      691px 2841px #fff, 1934px 1191px #fff, 1718px 2025px #fff, 1427px 919px #fff,
      75px 2555px #fff, 1589px 1201px #fff, 1057px 553px #fff;
    animation: animStar 50s linear infinite;
  }
  #stars:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 2115px 1740px #fff, 2567px 193px #fff, 3000px 2063px #fff,
      1163px 2284px #fff, 2538px 2259px #fff, 1185px 1592px #fff,
      2482px 1218px #fff, 1906px 1965px #fff, 1233px 2317px #fff, 551px 647px #fff,
      1359px 225px #fff, 1410px 882px #fff, 2790px 1158px #fff, 2289px 2820px #fff,
      925px 2071px #fff, 363px 2774px #fff, 426px 102px #fff, 1045px 1304px #fff,
      1737px 1070px #fff, 2553px 150px #fff, 2040px 2413px #fff,
      1512px 2901px #fff, 1668px 1084px #fff, 1521px 1947px #fff, 462px 665px #fff,
      1020px 1254px #fff, 810px 2727px #fff, 1755px 1751px #fff,
      1002px 2043px #fff, 731px 1773px #fff, 2911px 1713px #fff,
      1989px 2054px #fff, 1459px 2043px #fff, 1911px 1723px #fff,
      1294px 427px #fff, 2239px 1683px #fff, 1202px 2089px #fff, 436px 338px #fff,
      234px 2282px #fff, 236px 1309px #fff, 562px 1897px #fff, 2280px 2143px #fff,
      1419px 317px #fff, 431px 110px #fff, 314px 2820px #fff, 2133px 625px #fff,
      106px 1653px #fff, 2784px 443px #fff, 521px 1698px #fff, 138px 1499px #fff,
      2587px 270px #fff, 1307px 2750px #fff, 1610px 1657px #fff, 362px 2877px #fff,
      2580px 2294px #fff, 1533px 1455px #fff, 167px 2436px #fff, 1000px 729px #fff,
      2033px 1901px #fff, 953px 559px #fff, 2789px 2764px #fff, 1130px 1087px #fff,
      254px 1778px #fff, 2684px 1158px #fff, 1256px 2160px #fff, 301px 2873px #fff,
      1823px 2889px #fff, 303px 2060px #fff, 1866px 2313px #fff,
      2489px 2254px #fff, 1953px 1819px #fff, 395px 1324px #fff,
      2453px 1756px #fff, 872px 809px #fff, 1434px 2757px #fff, 2617px 2807px #fff,
      53px 2397px #fff, 855px 949px #fff, 2698px 274px #fff, 2710px 2276px #fff,
      90px 1721px #fff, 2805px 739px #fff, 999px 2218px #fff, 1063px 2925px #fff,
      168px 556px #fff, 146px 76px #fff, 231px 1230px #fff, 2308px 957px #fff,
      1750px 2274px #fff, 897px 525px #fff, 206px 1362px #fff, 138px 2616px #fff,
      1196px 2136px #fff, 897px 2338px #fff, 1081px 1525px #fff, 2822px 110px #fff,
      799px 2176px #fff, 2589px 2031px #fff, 2627px 226px #fff, 1414px 2348px #fff,
      679px 874px #fff, 1060px 1142px #fff, 2577px 2415px #fff, 2413px 255px #fff,
      2708px 219px #fff, 2398px 1253px #fff, 2559px 936px #fff, 624px 818px #fff,
      1519px 741px #fff, 1346px 2826px #fff, 696px 835px #fff, 72px 1873px #fff,
      2021px 2566px #fff, 2079px 1872px #fff, 2226px 2935px #fff, 158px 901px #fff,
      1056px 2667px #fff, 1983px 2181px #fff, 2865px 2467px #fff,
      2300px 1393px #fff, 1761px 2427px #fff, 2544px 1279px #fff,
      1124px 184px #fff, 1992px 2731px #fff, 259px 1676px #fff, 196px 557px #fff,
      2671px 678px #fff, 1136px 97px #fff, 322px 1073px #fff, 258px 1749px #fff,
      664px 2811px #fff, 2170px 2572px #fff, 1248px 406px #fff, 2170px 937px #fff,
      2151px 982px #fff, 1108px 644px #fff, 2400px 311px #fff, 2357px 2360px #fff,
      2252px 2232px #fff, 1333px 299px #fff, 2121px 1139px #fff,
      2728px 2533px #fff, 1919px 491px #fff, 2559px 2962px #fff, 404px 1074px #fff,
      2628px 1184px #fff, 2596px 2220px #fff, 2081px 1863px #fff,
      2605px 1097px #fff, 521px 1352px #fff, 1048px 2875px #fff,
      2447px 1816px #fff, 1078px 794px #fff, 199px 585px #fff, 1445px 713px #fff,
      2515px 1656px #fff, 638px 1970px #fff, 1488px 944px #fff, 1681px 1737px #fff,
      1091px 2636px #fff, 2408px 2708px #fff, 1978px 1935px #fff,
      2419px 257px #fff, 1268px 2276px #fff, 1676px 2569px #fff,
      2873px 2065px #fff, 2231px 947px #fff, 1174px 48px #fff, 2812px 1288px #fff,
      128px 2259px #fff, 2520px 1445px #fff, 776px 1235px #fff, 21px 1492px #fff,
      2102px 439px #fff, 165px 554px #fff, 1346px 1994px #fff, 1218px 1457px #fff,
      565px 357px #fff, 1270px 868px #fff, 1881px 2062px #fff, 2690px 2664px #fff,
      2464px 1772px #fff, 1962px 2898px #fff, 254px 2721px #fff, 2179px 963px #fff,
      1003px 529px #fff, 71px 1322px #fff, 2978px 1024px #fff, 773px 147px #fff,
      184px 162px #fff, 2482px 1774px #fff, 2845px 137px #fff, 917px 902px #fff,
      1888px 503px #fff, 2731px 2053px #fff, 2451px 2839px #fff,
      2473px 1401px #fff, 272px 143px #fff, 1598px 727px #fff, 270px 54px #fff,
      1236px 290px #fff, 448px 2024px #fff, 1994px 1250px #fff, 1739px 1693px #fff,
      23px 2498px #fff, 1269px 504px #fff, 1257px 1421px #fff, 222px 917px #fff,
      2326px 2993px #fff, 1294px 2052px #fff, 1120px 2395px #fff,
      2144px 749px #fff, 1396px 113px #fff, 2881px 1639px #fff, 1352px 1906px #fff,
      2269px 1160px #fff, 1108px 2724px #fff, 1785px 1346px #fff,
      1334px 1995px #fff, 1191px 1960px #fff, 1409px 737px #fff,
      1299px 1800px #fff, 1382px 2126px #fff, 964px 576px #fff, 423px 887px #fff,
      2508px 336px #fff, 2586px 2591px #fff, 718px 1898px #fff, 106px 932px #fff,
      1525px 921px #fff, 2324px 725px #fff, 507px 2724px #fff, 1952px 1753px #fff,
      1789px 355px #fff, 1690px 279px #fff, 619px 1038px #fff, 984px 690px #fff,
      1275px 397px #fff, 2202px 2261px #fff, 2545px 1896px #fff, 1757px 995px #fff,
      294px 1775px #fff, 2954px 966px #fff, 953px 2584px #fff, 983px 2778px #fff,
      1691px 2295px #fff, 773px 349px #fff, 908px 1351px #fff, 613px 940px #fff,
      1609px 2386px #fff, 2029px 1798px #fff, 1410px 962px #fff, 310px 2953px #fff,
      1677px 654px #fff, 2046px 2087px #fff, 1251px 1877px #fff,
      1195px 2658px #fff, 2205px 23px #fff, 2607px 495px #fff, 1429px 1335px #fff,
      2181px 2247px #fff, 2828px 1835px #fff, 2484px 127px #fff,
      2096px 1121px #fff, 224px 1234px #fff, 267px 80px #fff, 2480px 1805px #fff,
      1443px 1876px #fff, 2197px 2434px #fff, 68px 2118px #fff, 1828px 1034px #fff,
      743px 91px #fff, 1807px 1829px #fff, 1778px 3000px #fff, 1905px 1267px #fff,
      2746px 497px #fff, 1993px 1417px #fff, 1750px 2843px #fff,
      2980px 2379px #fff, 957px 2661px #fff, 347px 1884px #fff, 2375px 274px #fff,
      1188px 545px #fff, 2328px 903px #fff, 2737px 817px #fff, 442px 112px #fff,
      344px 2944px #fff, 2445px 2003px #fff, 184px 2434px #fff, 2796px 1509px #fff,
      2986px 1493px #fff, 399px 1370px #fff, 845px 135px #fff, 2176px 811px #fff,
      134px 2916px #fff, 1177px 613px #fff, 244px 1944px #fff, 2173px 199px #fff,
      1429px 162px #fff, 1193px 1824px #fff, 1996px 1167px #fff, 822px 1104px #fff,
      164px 1447px #fff, 2735px 784px #fff, 2690px 1974px #fff, 2577px 904px #fff,
      866px 1229px #fff, 658px 510px #fff, 817px 923px #fff, 1980px 1545px #fff,
      1733px 2556px #fff, 524px 1804px #fff, 2762px 2231px #fff, 1594px 380px #fff,
      2227px 2978px #fff, 1472px 1988px #fff, 1193px 2774px #fff,
      467px 2354px #fff, 2694px 814px #fff, 1824px 239px #fff, 753px 1015px #fff,
      69px 973px #fff, 1277px 1883px #fff, 1453px 2042px #fff, 401px 478px #fff,
      2152px 588px #fff, 1148px 1101px #fff, 1932px 624px #fff, 1044px 2237px #fff,
      639px 185px #fff, 209px 2449px #fff, 1409px 2319px #fff, 2265px 1921px #fff,
      2094px 2850px #fff, 2315px 991px #fff, 2878px 2181px #fff, 2913px 965px #fff,
      1307px 2889px #fff, 1742px 1074px #fff, 2954px 1480px #fff,
      2012px 2977px #fff, 2479px 2495px #fff, 2933px 1174px #fff, 80px 1489px #fff,
      307px 987px #fff, 1159px 900px #fff, 863px 2644px #fff, 1665px 141px #fff,
      2169px 2760px #fff, 903px 2106px #fff, 1686px 2367px #fff, 662px 2478px #fff,
      563px 15px #fff, 793px 1243px #fff, 2190px 1840px #fff, 2023px 1174px #fff,
      2990px 752px #fff, 381px 2777px #fff, 209px 1590px #fff, 2541px 703px #fff,
      2288px 2386px #fff, 634px 1399px #fff, 1667px 1647px #fff,
      1805px 1269px #fff, 1580px 1260px #fff, 2883px 1981px #fff,
      2254px 293px #fff, 1857px 866px #fff, 1056px 343px #fff, 990px 173px #fff,
      794px 2976px #fff, 239px 1303px #fff, 1003px 1574px #fff, 2584px 1548px #fff,
      978px 2211px #fff, 335px 1050px #fff, 1397px 13px #fff, 897px 1972px #fff,
      2433px 69px #fff, 2273px 681px #fff, 1504px 899px #fff, 1452px 2842px #fff,
      2473px 996px #fff, 668px 1983px #fff, 2541px 1265px #fff, 2226px 2825px #fff,
      218px 734px #fff, 447px 2668px #fff, 1415px 1075px #fff, 1571px 2468px #fff,
      798px 638px #fff, 1808px 2526px #fff, 405px 769px #fff, 914px 1939px #fff,
      1671px 1380px #fff, 1714px 1458px #fff, 1916px 29px #fff, 2333px 1010px #fff,
      2884px 994px #fff, 1403px 1897px #fff, 2021px 2566px #fff, 246px 2023px #fff,
      2241px 2904px #fff, 145px 2666px #fff, 902px 2091px #fff, 1214px 935px #fff,
      1144px 526px #fff, 2098px 828px #fff, 2885px 2610px #fff, 2830px 1718px #fff,
      2417px 1730px #fff, 2011px 2354px #fff, 2654px 1979px #fff, 526px 776px #fff,
      2269px 1181px #fff, 990px 1505px #fff, 1074px 1179px #fff, 2420px 965px #fff,
      2645px 1003px #fff, 48px 1400px #fff, 967px 1481px #fff, 2860px 1637px #fff,
      595px 143px #fff, 2989px 1016px #fff, 2081px 833px #fff, 184px 2031px #fff,
      882px 565px #fff, 1716px 914px #fff, 1579px 2851px #fff, 1308px 1293px #fff,
      1482px 1191px #fff, 357px 1357px #fff, 2334px 2945px #fff, 47px 1566px #fff,
      1015px 659px #fff, 1686px 1073px #fff, 555px 353px #fff, 889px 1673px #fff,
      974px 351px #fff, 1263px 196px #fff, 2673px 827px #fff, 959px 273px #fff,
      109px 36px #fff, 1258px 441px #fff, 1420px 2361px #fff, 2291px 2071px #fff,
      1875px 1283px #fff, 131px 194px #fff, 1276px 1371px #fff, 59px 2299px #fff,
      629px 2342px #fff, 2207px 1641px #fff, 873px 2819px #fff, 578px 1516px #fff,
      306px 956px #fff, 726px 2053px #fff, 2636px 737px #fff, 1833px 1623px #fff,
      2359px 1251px #fff, 2550px 1657px #fff, 2932px 972px #fff,
      2183px 2542px #fff, 1943px 2229px #fff, 883px 1861px #fff, 689px 2171px #fff,
      1430px 473px #fff, 2350px 1520px #fff, 473px 516px #fff, 695px 826px #fff,
      84px 92px #fff, 2635px 483px #fff, 2750px 2360px #fff, 1438px 1211px #fff,
      892px 857px #fff, 1036px 45px #fff, 1770px 2955px #fff, 432px 164px #fff,
      775px 2580px #fff, 423px 2516px #fff, 2058px 585px #fff, 953px 349px #fff,
      2623px 18px #fff, 2369px 53px #fff, 2671px 2553px #fff, 649px 1534px #fff,
      975px 2096px #fff, 323px 2309px #fff, 1582px 312px #fff, 2076px 634px #fff,
      2700px 1485px #fff, 2411px 2916px #fff, 1361px 2834px #fff,
      1555px 2905px #fff, 2181px 1412px #fff, 221px 1482px #fff,
      2492px 2900px #fff, 1165px 291px #fff, 1398px 2859px #fff, 574px 288px #fff,
      1242px 1460px #fff, 2208px 2063px #fff, 2504px 2756px #fff,
      1456px 2452px #fff, 1484px 707px #fff, 2163px 2469px #fff,
      1316px 1724px #fff, 1201px 491px #fff, 1000px 1069px #fff,
      1901px 2471px #fff, 1794px 2998px #fff, 988px 943px #fff, 1542px 2705px #fff,
      2730px 715px #fff, 2256px 1160px #fff, 2160px 2539px #fff, 1540px 188px #fff,
      1241px 20px #fff, 2140px 1618px #fff, 209px 1252px #fff, 1586px 993px #fff,
      2197px 1601px #fff, 1919px 2867px #fff, 49px 2997px #fff, 2699px 2778px #fff,
      855px 2908px #fff, 1563px 1624px #fff, 1863px 1800px #fff,
      1443px 1540px #fff, 340px 2358px #fff, 576px 126px #fff, 1913px 2854px #fff,
      2190px 2636px #fff, 2978px 483px #fff, 1774px 2606px #fff,
      2946px 2094px #fff, 563px 152px #fff, 2168px 1233px #fff, 818px 2245px #fff,
      1142px 727px #fff, 207px 527px #fff, 397px 1232px #fff, 2787px 113px #fff,
      722px 2564px #fff, 2087px 1057px #fff, 577px 2271px #fff, 1630px 177px #fff,
      2254px 2716px #fff, 2278px 1991px #fff, 2457px 2933px #fff,
      1640px 784px #fff, 1929px 853px #fff, 2007px 2838px #fff, 2137px 287px #fff,
      2439px 18px #fff, 1570px 692px #fff, 2897px 264px #fff, 274px 360px #fff,
      77px 805px #fff, 232px 1719px #fff, 746px 687px #fff, 2535px 1679px #fff,
      616px 905px #fff, 2633px 2688px #fff, 2220px 1177px #fff, 1185px 1587px #fff,
      2274px 2219px #fff, 1416px 1073px #fff, 31px 1968px #fff, 1948px 2702px #fff,
      1890px 993px #fff, 2901px 764px #fff, 2386px 2633px #fff, 349px 1402px #fff,
      1264px 2744px #fff, 720px 969px #fff, 2210px 1437px #fff, 1039px 1915px #fff,
      846px 2779px #fff, 984px 422px #fff, 1823px 586px #fff, 2329px 2725px #fff,
      1783px 1326px #fff, 1768px 1557px #fff, 1843px 210px #fff,
      1717px 2643px #fff, 1526px 1137px #fff, 2334px 399px #fff, 528px 1081px #fff,
      699px 2666px #fff, 1654px 329px #fff, 2860px 2815px #fff, 2267px 485px #fff,
      1078px 1126px #fff, 864px 331px #fff, 2226px 361px #fff, 2568px 2091px #fff,
      2040px 2305px #fff, 1189px 1867px #fff, 442px 1827px #fff,
      1663px 2195px #fff, 2340px 2387px #fff, 329px 626px #fff, 2773px 1723px #fff,
      618px 1601px #fff, 895px 1788px #fff, 2776px 1519px #fff, 2660px 1111px #fff,
      789px 1717px #fff, 2377px 2003px #fff, 1042px 2980px #fff, 878px 2630px #fff,
      1248px 2002px #fff, 2519px 262px #fff, 886px 2354px #fff, 723px 126px #fff,
      1386px 1775px #fff, 338px 823px #fff, 155px 585px #fff, 319px 1667px #fff,
      54px 1625px #fff, 1332px 2016px #fff, 2628px 1562px #fff, 2126px 1353px #fff,
      2314px 2650px #fff, 2082px 2334px #fff, 2576px 148px #fff, 771px 1776px #fff,
      280px 2603px #fff, 2067px 1118px #fff, 621px 2155px #fff, 1556px 2427px #fff,
      912px 127px #fff, 650px 755px #fff, 1250px 403px #fff, 421px 1305px #fff,
      2339px 2246px #fff, 1167px 1234px #fff, 2586px 882px #fff, 2031px 784px #fff,
      2031px 164px #fff, 1851px 2645px #fff, 1207px 1257px #fff,
      2898px 2268px #fff, 2937px 709px #fff, 144px 249px #fff, 1899px 2716px #fff,
      2155px 1849px #fff, 202px 1296px #fff, 621px 2511px #fff, 903px 1045px #fff,
      1983px 2474px #fff, 1048px 2728px #fff, 2948px 2222px #fff,
      1375px 1863px #fff, 2670px 2568px #fff, 2449px 2633px #fff,
      2311px 2008px #fff, 7px 1338px #fff, 1452px 2131px #fff, 2474px 1283px #fff,
      1844px 877px #fff, 213px 1797px #fff, 64px 2891px #fff, 1510px 1071px #fff,
      1460px 1022px #fff, 1419px 1244px #fff, 1385px 330px #fff,
      2265px 1419px #fff, 1243px 2022px #fff, 2220px 959px #fff,
      1264px 1537px #fff, 436px 2500px #fff, 1798px 910px #fff, 59px 2421px #fff,
      2604px 1623px #fff, 101px 2928px #fff, 2283px 2338px #fff, 329px 1150px #fff,
      2343px 2421px #fff, 2750px 1120px #fff, 1255px 1748px #fff,
      1143px 2371px #fff, 212px 777px #fff, 871px 366px #fff, 356px 1905px #fff,
      289px 98px #fff, 1134px 2849px #fff, 1109px 1412px #fff, 1959px 991px #fff,
      2467px 817px #fff, 1501px 1263px #fff, 744px 2619px #fff, 749px 395px #fff,
      691px 2841px #fff, 1934px 1191px #fff, 1718px 2025px #fff, 1427px 919px #fff,
      75px 2555px #fff, 1589px 1201px #fff, 1057px 553px #fff;
  }
  
  #stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1254px 2252px #fff, 1426px 2391px #fff, 1336px 488px #fff,
      1570px 2307px #fff, 2296px 283px #fff, 2806px 2588px #fff, 2930px 192px #fff,
      1873px 1573px #fff, 2804px 2100px #fff, 1672px 2555px #fff,
      1888px 2691px #fff, 2209px 1650px #fff, 741px 1280px #fff,
      1367px 1325px #fff, 2045px 2360px #fff, 1748px 2983px #fff,
      509px 1571px #fff, 1237px 662px #fff, 2518px 212px #fff, 2494px 43px #fff,
      2764px 21px #fff, 3000px 1638px #fff, 2526px 777px #fff, 1351px 128px #fff,
      36px 957px #fff, 2239px 129px #fff, 1889px 992px #fff, 1667px 1932px #fff,
      734px 1610px #fff, 2791px 333px #fff, 2622px 1635px #fff, 1608px 281px #fff,
      21px 643px #fff, 2435px 852px #fff, 1311px 1526px #fff, 852px 2061px #fff,
      1402px 2872px #fff, 1234px 33px #fff, 402px 735px #fff, 1146px 1434px #fff,
      2087px 2463px #fff, 987px 1984px #fff, 699px 481px #fff, 838px 2880px #fff,
      1605px 488px #fff, 714px 2742px #fff, 2430px 1851px #fff, 494px 2763px #fff,
      2397px 956px #fff, 2747px 720px #fff, 2466px 444px #fff, 1184px 2946px #fff,
      955px 2564px #fff, 1060px 1233px #fff, 2851px 1302px #fff, 917px 686px #fff,
      1381px 288px #fff, 2891px 2947px #fff, 2329px 1549px #fff,
      1482px 2138px #fff, 2392px 2590px #fff, 2158px 826px #fff, 554px 2645px #fff,
      2203px 168px #fff, 421px 728px #fff, 364px 475px #fff, 2587px 22px #fff,
      422px 739px #fff, 2537px 411px #fff, 898px 2547px #fff, 1179px 1543px #fff,
      1963px 404px #fff, 446px 2117px #fff, 2108px 2312px #fff, 848px 2500px #fff,
      2787px 734px #fff, 2771px 2541px #fff, 490px 2958px #fff, 1981px 1976px #fff,
      1597px 2861px #fff, 430px 1838px #fff, 2016px 2256px #fff, 443px 872px #fff,
      2437px 2135px #fff, 2082px 2653px #fff, 2619px 1856px #fff,
      646px 1621px #fff, 2042px 2584px #fff, 1955px 1093px #fff, 2807px 774px #fff,
      2727px 2117px #fff, 1422px 2665px #fff, 320px 2811px #fff, 449px 1115px #fff,
      409px 115px #fff, 1576px 1530px #fff, 2287px 1120px #fff, 2201px 11px #fff,
      221px 2256px #fff, 1601px 2834px #fff, 329px 1076px #fff, 2940px 49px #fff,
      1303px 2063px #fff, 2174px 1660px #fff, 2012px 204px #fff, 2669px 614px #fff,
      2413px 1339px #fff, 305px 2129px #fff, 954px 1397px #fff, 412px 2194px #fff,
      1413px 1736px #fff, 428px 1085px #fff, 1922px 1359px #fff,
      2978px 1631px #fff, 1483px 776px #fff, 2147px 186px #fff, 1653px 85px #fff,
      706px 1534px #fff, 684px 2646px #fff, 2548px 2734px #fff, 2711px 443px #fff,
      1703px 786px #fff, 2628px 1583px #fff, 408px 1597px #fff, 1387px 1261px #fff,
      591px 1404px #fff, 186px 922px #fff, 2663px 331px #fff, 2635px 276px #fff,
      98px 1315px #fff, 816px 865px #fff, 2647px 2537px #fff, 1718px 566px #fff,
      2154px 83px #fff, 1569px 2652px #fff, 2420px 1616px #fff, 1432px 2269px #fff,
      2231px 2066px #fff, 2539px 572px #fff, 1459px 79px #fff, 1891px 517px #fff,
      1785px 1271px #fff, 25px 2159px #fff, 2081px 422px #fff, 606px 2548px #fff,
      2616px 2059px #fff, 1937px 1972px #fff, 713px 378px #fff, 1929px 795px #fff,
      786px 548px #fff, 2556px 2485px #fff, 277px 642px #fff, 1924px 2184px #fff,
      505px 1642px #fff, 595px 2538px #fff, 1627px 1564px #fff, 974px 2981px #fff,
      1665px 1894px #fff, 2035px 820px #fff, 1538px 2064px #fff,
      1730px 1512px #fff, 1079px 2023px #fff, 2213px 2216px #fff, 987px 383px #fff,
      1624px 2493px #fff, 2532px 2742px #fff, 2785px 1385px #fff,
      279px 1371px #fff, 679px 1811px #fff, 891px 1166px #fff, 1159px 887px #fff,
      2996px 455px #fff, 92px 71px #fff, 2971px 1247px #fff, 2264px 1454px #fff,
      1900px 1490px #fff, 2288px 1504px #fff, 2614px 1428px #fff,
      1048px 627px #fff, 2859px 2235px #fff, 663px 681px #fff, 1464px 988px #fff,
      288px 1243px #fff, 1201px 596px #fff, 1553px 2890px #fff, 1722px 711px #fff,
      1909px 2814px #fff, 413px 2944px #fff, 1569px 633px #fff, 2381px 2374px #fff,
      2281px 1423px #fff, 1917px 2961px #fff, 893px 64px #fff, 999px 2808px #fff,
      893px 449px #fff, 1978px 903px #fff, 1025px 161px #fff, 1577px 1376px #fff,
      338px 2359px #fff, 169px 40px #fff;
    animation: animStar 100s linear infinite;
  }
  #stars2:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1254px 2252px #fff, 1426px 2391px #fff, 1336px 488px #fff,
      1570px 2307px #fff, 2296px 283px #fff, 2806px 2588px #fff, 2930px 192px #fff,
      1873px 1573px #fff, 2804px 2100px #fff, 1672px 2555px #fff,
      1888px 2691px #fff, 2209px 1650px #fff, 741px 1280px #fff,
      1367px 1325px #fff, 2045px 2360px #fff, 1748px 2983px #fff,
      509px 1571px #fff, 1237px 662px #fff, 2518px 212px #fff, 2494px 43px #fff,
      2764px 21px #fff, 3000px 1638px #fff, 2526px 777px #fff, 1351px 128px #fff,
      36px 957px #fff, 2239px 129px #fff, 1889px 992px #fff, 1667px 1932px #fff,
      734px 1610px #fff, 2791px 333px #fff, 2622px 1635px #fff, 1608px 281px #fff,
      21px 643px #fff, 2435px 852px #fff, 1311px 1526px #fff, 852px 2061px #fff,
      1402px 2872px #fff, 1234px 33px #fff, 402px 735px #fff, 1146px 1434px #fff,
      2087px 2463px #fff, 987px 1984px #fff, 699px 481px #fff, 838px 2880px #fff,
      1605px 488px #fff, 714px 2742px #fff, 2430px 1851px #fff, 494px 2763px #fff,
      2397px 956px #fff, 2747px 720px #fff, 2466px 444px #fff, 1184px 2946px #fff,
      955px 2564px #fff, 1060px 1233px #fff, 2851px 1302px #fff, 917px 686px #fff,
      1381px 288px #fff, 2891px 2947px #fff, 2329px 1549px #fff,
      1482px 2138px #fff, 2392px 2590px #fff, 2158px 826px #fff, 554px 2645px #fff,
      2203px 168px #fff, 421px 728px #fff, 364px 475px #fff, 2587px 22px #fff,
      422px 739px #fff, 2537px 411px #fff, 898px 2547px #fff, 1179px 1543px #fff,
      1963px 404px #fff, 446px 2117px #fff, 2108px 2312px #fff, 848px 2500px #fff,
      2787px 734px #fff, 2771px 2541px #fff, 490px 2958px #fff, 1981px 1976px #fff,
      1597px 2861px #fff, 430px 1838px #fff, 2016px 2256px #fff, 443px 872px #fff,
      2437px 2135px #fff, 2082px 2653px #fff, 2619px 1856px #fff,
      646px 1621px #fff, 2042px 2584px #fff, 1955px 1093px #fff, 2807px 774px #fff,
      2727px 2117px #fff, 1422px 2665px #fff, 320px 2811px #fff, 449px 1115px #fff,
      409px 115px #fff, 1576px 1530px #fff, 2287px 1120px #fff, 2201px 11px #fff,
      221px 2256px #fff, 1601px 2834px #fff, 329px 1076px #fff, 2940px 49px #fff,
      1303px 2063px #fff, 2174px 1660px #fff, 2012px 204px #fff, 2669px 614px #fff,
      2413px 1339px #fff, 305px 2129px #fff, 954px 1397px #fff, 412px 2194px #fff,
      1413px 1736px #fff, 428px 1085px #fff, 1922px 1359px #fff,
      2978px 1631px #fff, 1483px 776px #fff, 2147px 186px #fff, 1653px 85px #fff,
      706px 1534px #fff, 684px 2646px #fff, 2548px 2734px #fff, 2711px 443px #fff,
      1703px 786px #fff, 2628px 1583px #fff, 408px 1597px #fff, 1387px 1261px #fff,
      591px 1404px #fff, 186px 922px #fff, 2663px 331px #fff, 2635px 276px #fff,
      98px 1315px #fff, 816px 865px #fff, 2647px 2537px #fff, 1718px 566px #fff,
      2154px 83px #fff, 1569px 2652px #fff, 2420px 1616px #fff, 1432px 2269px #fff,
      2231px 2066px #fff, 2539px 572px #fff, 1459px 79px #fff, 1891px 517px #fff,
      1785px 1271px #fff, 25px 2159px #fff, 2081px 422px #fff, 606px 2548px #fff,
      2616px 2059px #fff, 1937px 1972px #fff, 713px 378px #fff, 1929px 795px #fff,
      786px 548px #fff, 2556px 2485px #fff, 277px 642px #fff, 1924px 2184px #fff,
      505px 1642px #fff, 595px 2538px #fff, 1627px 1564px #fff, 974px 2981px #fff,
      1665px 1894px #fff, 2035px 820px #fff, 1538px 2064px #fff,
      1730px 1512px #fff, 1079px 2023px #fff, 2213px 2216px #fff, 987px 383px #fff,
      1624px 2493px #fff, 2532px 2742px #fff, 2785px 1385px #fff,
      279px 1371px #fff, 679px 1811px #fff, 891px 1166px #fff, 1159px 887px #fff,
      2996px 455px #fff, 92px 71px #fff, 2971px 1247px #fff, 2264px 1454px #fff,
      1900px 1490px #fff, 2288px 1504px #fff, 2614px 1428px #fff,
      1048px 627px #fff, 2859px 2235px #fff, 663px 681px #fff, 1464px 988px #fff,
      288px 1243px #fff, 1201px 596px #fff, 1553px 2890px #fff, 1722px 711px #fff,
      1909px 2814px #fff, 413px 2944px #fff, 1569px 633px #fff, 2381px 2374px #fff,
      2281px 1423px #fff, 1917px 2961px #fff, 893px 64px #fff, 999px 2808px #fff,
      893px 449px #fff, 1978px 903px #fff, 1025px 161px #fff, 1577px 1376px #fff,
      338px 2359px #fff, 169px 40px #fff;
  }
  
  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 258px 644px #fff, 1924px 1346px #fff, 2490px 2877px #fff,
      105px 1093px #fff, 1096px 2587px #fff, 70px 629px #fff, 1858px 2779px #fff,
      992px 650px #fff, 2239px 2065px #fff, 150px 157px #fff, 2145px 822px #fff,
      279px 5px #fff, 209px 235px #fff, 767px 802px #fff, 1009px 579px #fff,
      2632px 2930px #fff, 2422px 1843px #fff, 473px 895px #fff, 2637px 1798px #fff,
      2904px 2104px #fff, 2601px 865px #fff, 282px 862px #fff, 2893px 1658px #fff,
      537px 1262px #fff, 225px 2212px #fff, 2035px 2390px #fff, 1861px 501px #fff,
      457px 2228px #fff, 166px 2587px #fff, 2165px 189px #fff, 90px 2307px #fff,
      630px 1148px #fff, 1340px 2728px #fff, 2996px 2746px #fff,
      2669px 1762px #fff, 2134px 90px #fff, 508px 1541px #fff, 282px 823px #fff,
      1708px 2828px #fff, 704px 2120px #fff, 1622px 763px #fff, 1594px 1403px #fff,
      1623px 2916px #fff, 1340px 1832px #fff, 2914px 1136px #fff,
      2083px 1738px #fff, 1901px 1752px #fff, 908px 1120px #fff, 676px 518px #fff,
      1317px 2015px #fff, 1997px 2711px #fff, 2628px 2746px #fff,
      2766px 1440px #fff, 1835px 2270px #fff, 735px 2181px #fff, 425px 2531px #fff,
      1516px 756px #fff, 1979px 857px #fff, 1057px 2448px #fff, 1168px 1297px #fff,
      2115px 2442px #fff, 890px 1521px #fff, 2948px 1133px #fff, 2175px 571px #fff,
      2891px 288px #fff, 2681px 2818px #fff, 558px 1753px #fff, 83px 1228px #fff,
      2862px 644px #fff, 497px 1923px #fff, 1341px 1944px #fff, 1512px 1326px #fff,
      1533px 594px #fff, 807px 2502px #fff, 2249px 355px #fff, 2971px 516px #fff,
      1783px 200px #fff, 1491px 2790px #fff, 1193px 2902px #fff, 246px 2408px #fff,
      2565px 182px #fff, 1398px 200px #fff, 2003px 2172px #fff, 1908px 2027px #fff,
      2381px 1239px #fff, 1262px 2635px #fff, 1396px 43px #fff, 506px 2520px #fff,
      2570px 387px #fff, 1348px 1518px #fff, 626px 1778px #fff, 2564px 1239px #fff,
      1007px 1681px #fff, 167px 2556px #fff, 818px 1877px #fff, 2478px 174px #fff,
      2723px 2765px #fff, 2010px 121px #fff, 406px 2375px #fff, 698px 2270px #fff;
    animation: animStar 150s linear infinite;
  }
  #stars3:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 258px 644px #fff, 1924px 1346px #fff, 2490px 2877px #fff,
      105px 1093px #fff, 1096px 2587px #fff, 70px 629px #fff, 1858px 2779px #fff,
      992px 650px #fff, 2239px 2065px #fff, 150px 157px #fff, 2145px 822px #fff,
      279px 5px #fff, 209px 235px #fff, 767px 802px #fff, 1009px 579px #fff,
      2632px 2930px #fff, 2422px 1843px #fff, 473px 895px #fff, 2637px 1798px #fff,
      2904px 2104px #fff, 2601px 865px #fff, 282px 862px #fff, 2893px 1658px #fff,
      537px 1262px #fff, 225px 2212px #fff, 2035px 2390px #fff, 1861px 501px #fff,
      457px 2228px #fff, 166px 2587px #fff, 2165px 189px #fff, 90px 2307px #fff,
      630px 1148px #fff, 1340px 2728px #fff, 2996px 2746px #fff,
      2669px 1762px #fff, 2134px 90px #fff, 508px 1541px #fff, 282px 823px #fff,
      1708px 2828px #fff, 704px 2120px #fff, 1622px 763px #fff, 1594px 1403px #fff,
      1623px 2916px #fff, 1340px 1832px #fff, 2914px 1136px #fff,
      2083px 1738px #fff, 1901px 1752px #fff, 908px 1120px #fff, 676px 518px #fff,
      1317px 2015px #fff, 1997px 2711px #fff, 2628px 2746px #fff,
      2766px 1440px #fff, 1835px 2270px #fff, 735px 2181px #fff, 425px 2531px #fff,
      1516px 756px #fff, 1979px 857px #fff, 1057px 2448px #fff, 1168px 1297px #fff,
      2115px 2442px #fff, 890px 1521px #fff, 2948px 1133px #fff, 2175px 571px #fff,
      2891px 288px #fff, 2681px 2818px #fff, 558px 1753px #fff, 83px 1228px #fff,
      2862px 644px #fff, 497px 1923px #fff, 1341px 1944px #fff, 1512px 1326px #fff,
      1533px 594px #fff, 807px 2502px #fff, 2249px 355px #fff, 2971px 516px #fff,
      1783px 200px #fff, 1491px 2790px #fff, 1193px 2902px #fff, 246px 2408px #fff,
      2565px 182px #fff, 1398px 200px #fff, 2003px 2172px #fff, 1908px 2027px #fff,
      2381px 1239px #fff, 1262px 2635px #fff, 1396px 43px #fff, 506px 2520px #fff,
      2570px 387px #fff, 1348px 1518px #fff, 626px 1778px #fff, 2564px 1239px #fff,
      1007px 1681px #fff, 167px 2556px #fff, 818px 1877px #fff, 2478px 174px #fff,
      2723px 2765px #fff, 2010px 121px #fff, 406px 2375px #fff, 698px 2270px #fff;
  }
  
  @keyframes animStar {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-2000px);
    }
  }
  