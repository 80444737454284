* {
    color: white;
    font-family: "Righteous", cursive;
    z-index: 1;
  }
  
  .footer__class {
    height: 100%;
    width: 100%;
    background-color: rgb(55, 66, 91) !important;
    padding-bottom: 70px;
  }
  
  .footer__container {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    margin-right: 15%;
  }
  
  .footer__class h1 {
    width: 70%;
    padding: 0 0;
    margin: 0 0;
    padding-top: 70px;
  }
  
  .footer__container p {
    font-size: 0.6rem;
    width: 400px;
    color: white;
    opacity: 0.7;
  }
  
  .footer__icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    min-height: 60px;
  }
  
  .footer__icon {
    margin: 0 10px;
  }
  
  .footer__icon:hover a svg {
    width: 3em !important;
    height: 3em !important;
  }
  
  .footer__info {
    text-align: center;
    padding-top: 30px;
    opacity: 0.7;
    margin-bottom: -40px;
  }
  
  .footer__button {
    display: flex;
    justify-content: space-between;
  }
  
  .button__mint a {
    text-decoration: none;
  }
  
  .button__mint__footer { 
    font-size: 0.64rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #d75a00 !important;
    border-color: #d75a00 !important;
    padding: 15px 60px;
    color: white;
    cursor: pointer;
    right: 400px;
    position: absolute;
    border-radius: 5px;
  }


  @media only screen and (max-width: 460px) {
    .button__mint__footer {
      display: none !important;
    }
  }
  