#title {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #title__header {
    margin-bottom: 20px;
    margin-top: -100px;
  }
  
  #title__header span {
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: "Righteous", cursive;
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 10px;
  }
    
  .button__mint {
    font-size: 0.64rem;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
    background-color: #d75a00 !important;
    border-color: #d75a00 !important; 
    padding: 30px 60px;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  
  .pos1 {
    margin-left: -1000px;
    opacity: .7;
    position: absolute;
    width: 400px;
}
  .pos2 {
    position: absolute;
    left: 400px;
    top: -300px;
    width: 100px;
  }
  
  .pos3 {
    position: absolute;
    margin-left: 550px;
    width: 100px;
  }
  
  .pos4 {
    position: absolute;
    margin-left: 600px;
    margin-bottom: 10px;
    width: 400px;
  }
  
  .pos5 {
    position: absolute;
    left: -700px;
    top: -300px;
    width: 200px;
  }

  #gen3 {
    z-index: 0;
    margin-top: -50px;
   background-size: cover;
     /* background-position: center; */
    /* background-repeat: no-repeat; */ 
    opacity: 0.8;
  } 

  #countdown { 
    font-size: xx-large;
  }

  .container { 
    max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  }
  
  @media only screen and (max-width: 460px) {
    .pos1, .pos2, .pos3, .pos4, .pos5 {
      display: none;
    }
    #title__header { 
      margin-top: 10px !important;
    }
    #title__header span{ 
      font-size: 1rem;
      margin-top: 0;
    }

  }

  @media (max-width: 767px) {
    #gen3 {
      max-width: 100% !important;
      height: auto !important;
      margin-top: -150px;
      transform: scale(1) !important;
    }

    #countdown { 
      font-size: large;
      position: absolute;
      margin-top: 100px !important;
    }
  }

  @media (max-width: 2330px) {
    #gen3 {
      transform: scale(0.8);
    }

    #countdown { 
      position: absolute;
      margin-top: 900px;
     
    }
  }
