.mint__class {
    height: 100vh;
    position: relative;
    max-width: 1400px;
    margin: auto auto;
  }
  
  .mint__h1 {
    text-transform: uppercase;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 70px;
    padding-bottom: 40px;
  }
  
  .mint__text {
    height: 20vh;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
  
  }
  
  .mint__text__link { 
    display: flex;
    justify-content: center;
    line-height: 2.5;
    font-size: 1.2rem;
  }
  
  .mint__text__link a{ 
  text-decoration: none;
  color: #4cc36c !important; 
  }
  
  .mint__text__icons {
    max-width: 1000px;
    margin: auto auto;
    text-align: center;
    margin-top: 50px;
  }
  
  .mint__text__icons a{
  text-decoration: none;
  }
  
  .mint__text__icons a:hover{
    color: #d75a00;
    }
  .mint__img { 
    position: absolute;
   left: 200px;
   top: 470px;
    /*  bottom: 400px; */
  }
  
  .mint__img img { 
    width: 200px;
  }
  
  
  .pos55 {
    position: absolute;
    left: -350px;
    width: 300px;
  }

  .mint__text__icons > .span__if { 
    color: red !important;
    font-size: 18px;
    font-weight: 600;
  }
    
  @media only screen and (max-width: 460px) {
    .mint__class { 
      height: 100%;
    }
   .pos5, .mint__img img { 
     display: none;
   }

   .mint__h1 { 
     font-size: 1.5rem;
   }
   .mint__text { 
     font-size: 0.6rem; 
       text-align: center;
     padding: 10px 10px;
   }
   .mint__text__icons { 
     font-size: 0.6rem;
     text-align: center;
     padding: 10px 10px;
   }
   .mint__text__icons > .span__if { 
    color: red !important;
    font-size: 10px;
    font-weight: 600;
    }
  }
  @media only screen and (max-width: 4000px) and (min-width: 2000px)  {  .mint__img { 
    bottom: 600px;
  }}

