.roadmap__class {
    height: 100vh;
  }
  
  .roadmap__h1 {
    text-transform: uppercase;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 70px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 40px;
  }
  
  .roadmap__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
  }

  .mobile { 
    display: none;
  }
  
  .pos3 {
    position: absolute;
    left: -450px;
    width: 300px;
    top: -50px;
  }
  
  @media only screen and (max-width: 460px) {
    .roadmap__class { 
      height: 100%;
    }
    .pos3, .desktop{
      display: none;
    }
    .roadmap__h1 { 
      margin-top: 40px;
      font-size: 1.5rem;
    }

    .mobile { 
      display: flex;
      justify-content: center;
      align-items: center;
      height: 700px;
      margin-bottom: 0;
    }
  }
  