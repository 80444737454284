.team__class {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .team__imgs {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .team__img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .team__h1 {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-size: 2rem;
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    padding-bottom: 40px;
  }
  
  .team__user {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: -webkit-linear-gradient(white, #fcfdff);
    background-clip: text;
    font-weight: 700;
    font-size: 1rem;
  }
  
  .pos1 {
    position: absolute;
    width: 400px;
    margin-left: -1000px;
    opacity: 0.7;
  }
  
  .team__name {
    opacity: 0.8;
    margin: 5px 0;
    font-size: 15px;
  }

  @media only screen and (max-width: 460px) {
    .team__class { 
      margin-bottom: 40px;
    }

    .team__h1 { 
      font-size: 1.5rem;
    }
    .team__imgs{
      display: flex;
      flex-direction: column;
    }
    .team__user {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .mobile__block { 
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto 0;
    }
   
    .team__user h6 { 
      font-size: 0.5rem;
    }
    .team__img { 
      height: 100px;
      width: 100px;
    }
  }
  