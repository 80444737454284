#menu__class {
    height: 100px;
}

#menu { 
    border-bottom: 2px solid rgba(240,248,255,.5);
}

.menu__brand { 
    font-size: 1.5rem !important;
}

.menu__links { 
    font-size: 1rem;
}

.menu__link { 
    margin: 0px 10px;
 
}

.menu__chia { 
    background-color: transparent;
    width: 100px;
}

.menu__discord { 
    background: #d75a00 !important;
    border-color: #d75a00 !important;
    padding: 10px 30px;
    text-transform: uppercase;
    border-radius: 4px;
}

@media only screen and (max-width: 460px) {
    .menu__brand {
      font-size: 1rem !important;
    }

    .navbar-dark .navbar-toggler { 
        border-color: white !important;
    }

    .menu__chia { 
        width: 75px;
    }

    .menu__discord { 
        padding: 5px 15px;
        font-size: 0.7rem;
    }
    
    .nav-link { 
        padding: 0.2rem 0.5rem !important;
    }
  }